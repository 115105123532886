@use "../abstracts/mixins";

.rt-stores-area {
  // stores area
  --padding-bottom-stores-area: 2em;

  // stores list
  --gap-stores-list: 3em;

  // store card
  --min-width-store-card: 350px;
  --max-width-store-card: 600px;

  // stores count
  --padding-block-stores-count: 2em;
}

.rt-stores-area {
  overflow-y: auto;
  padding-bottom: var(--padding-bottom-stores-area);
}

.rt-stores-count {
  display: flex;
  gap: 0.35em;
  padding-top: var(--padding-block-stores-count);
  padding-bottom: var(--padding-block-stores-count);

  text-transform: uppercase;
}

.rt-stores-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(var(--min-width-store-card), 100%), 1fr)
  );
  gap: var(--gap-stores-list);

  > * {
    article {
      height: 100%;
    }

    &:only-child {
      @include mixins.desktop-down {
        grid-column: 1 / -1;

        margin: auto;
      }
    }
  }
}
