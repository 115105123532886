// only used for swiper's dots

// concierge sections bg
$clr-gray-400: #f0f0f0;

// texts
$clr-gray-700: #232323;

$clr-black: black;

$clr-white: white;
