@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-btn.rt-btn {
  $gap-btn: 1em;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: $gap-btn;

  padding: 0.7em 1.5em;
  border: 1px solid;

  font-size: typography.$fs-400;
  font-weight: typography.$fw-500;
  text-align: center;
  text-transform: uppercase;

  border-radius: 100vmax;

  @include mixins.opacity-fade-on-hover();

  &.rt-btn-primary {
    background: colors.$clr-black;
    color: colors.$clr-white;
    border-color: colors.$clr-black;
  }

  &.rt-btn-secondary {
    background-color: colors.$clr-white;
    color: colors.$clr-black;
  }

  &.rt-btn-tertiary {
    background-color: transparent;
    color: colors.$clr-black;
  }
}

.rt-geo-controls__nearest-btn.rt-geo-controls__nearest-btn {
  justify-content: space-between;

  @include mixins.pseudo(24px, 24px, "", "::after");

  &::after {
    background-image: url("/img/icon/gps.svg");
  }
}
