@use "../abstracts/typography";
@use "../abstracts/colors";
@use "../abstracts/mixins";

// * grid-related stuff
.rt-header {
  .rt-main-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
      "logo nav"
      "geo geo";
    gap: 1.5em;

    @include mixins.large-desktop-up {
      grid-template-columns: 1fr 3fr 1fr;
      grid-template-areas: "logo geo nav";
      align-items: center;
    }
  }
}

.rt-logo {
  grid-area: logo;
}

.rt-main-navigation {
  grid-area: nav;
  justify-self: end;
  align-self: start;

  @include mixins.desktop-up {
    align-self: center;
  }
}

.rt-geo-controls {
  grid-area: geo;

  > * {
    width: 100%;
  }
}

// * general stuff
.rt-header {
  --padding-top-header: 1em;
  --padding-bottom-header: 2em;

  background-color: colors.$clr-white;
  padding-top: var(--padding-top-header);
  padding-bottom: var(--padding-bottom-header);

  @include mixins.desktop-up {
    --padding-top-header: 2em;
    border-bottom: 1px solid colors.$clr-black;
  }
}

.rt-geo-controls {
  font-size: typography.$fs-450;
  --padding-geo-controls: 0.6em 1.5em;
}

// * logo
.rt-logo {
  display: flex;
  flex-direction: column;
  gap: 0.5em 1em;

  @include mixins.desktop-up {
    flex-direction: row;
    align-items: baseline;
  }

  > * {
    font-size: 14px;
    font-weight: typography.$fw-500;
    text-transform: uppercase;

    // @include mixins.desktop-up {
    //   font-size: 16px;
    // }
  }

  img {
    width: 110px;

    @include mixins.desktop-up {
      width: 130px;
    }
  }
}

// * geo controls
.rt-geo-controls {
  display: grid;
  // ! This makes equal-height rows in mobile
  grid-auto-rows: 1fr;
  gap: 1em;

  @include mixins.desktop-up {
    grid-auto-flow: column;
    grid-auto-columns: minmax(380px, 1fr);
  }

  @include mixins.large-desktop-up {
    max-width: 750px;
    justify-self: center;
  }

  > * {
    min-height: 40px;
  }
}

// * main navigation
.rt-main-navigation {
  display: flex;
  align-items: center;
  gap: 32px;

  text-align: center;
  text-transform: capitalize;

  &__language {
    text-transform: uppercase;
  }

  // @include mixins.desktop-up {
  //   gap: 40px;
  //   font-size: typography.$fs-500;
  // }
}

// ! Adjustments for concierge/sitemap
.rt-concierge,
.rt-sitemap {
  .rt-header .rt-main-container {
    --main-container-max-width: 1800px;
    --main-container-relative-width: 95%;

    grid-template-columns: auto auto;
    grid-template-areas:
      "logo nav"
      "geo  geo";
    row-gap: 0;
  }
}
