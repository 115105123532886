.rt-toast-container.rt-toast-container {
  z-index: 99999;

  p {
    margin: 0;
    font-family: revert;
    font-size: revert;
    color: revert;
  }
}

// TODO: delete this
// =========================================================
.rt-toast-container *,
.rt-toast-container *::before,
.rt-toast-container *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.rt-toast-container {
  --clr-message: #666;
  --clr-progress-bar-left: #414141;
  --clr-progress-bar-right: #bfbfbf;
  --width-toast: 340px;
  --border-radius-toast: 5px;
  --offset-toast-x: 30px;
  --offset-toast-y: 30px;
  --animation-timing-function-toast: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --animation-duration-toast: 3s;
  display: none;
  position: fixed;
  z-index: 20;
  width: var(--width-toast);
  min-height: 70px;
  background: #fff no-repeat right 10px top 10px/15px 15px;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(187, 187, 187, 0.75);
  border-radius: var(--border-radius-toast);
  animation-duration: var(--animation-duration-toast);
  animation-timing-function: var(--animation-timing-function-toast);
}
.rt-toast-container:hover {
  animation-play-state: paused;
}
.rt-toast-container--top-right {
  top: var(--offset-toast-y);
}
.rt-toast-container--bottom-right {
  bottom: var(--offset-toast-y);
}
.rt-toast-container--bottom-left {
  bottom: var(--offset-toast-y);
}
.rt-toast-container--top-left {
  top: var(--offset-toast-y);
}
.rt-toast-container--animated {
  display: block;
}
.rt-toast-container.rt-toast-container--clicked:hover {
  animation-play-state: running;
}
.rt-toast-container.rt-toast-container--clicked:hover
  .rt-toast-container__progress-bar {
  animation-play-state: running;
}
.rt-toast-container--top-right.rt-toast-container--animated {
  animation-name: containerRight;
}
.rt-toast-container--bottom-right.rt-toast-container--animated {
  animation-name: containerRight;
}
.rt-toast-container--bottom-left.rt-toast-container--animated {
  animation-name: containerLeft;
}
.rt-toast-container--top-left.rt-toast-container--animated {
  animation-name: containerLeft;
}
.rt-toast-container--top-right.rt-toast-container--clicked {
  animation: 0.3s containerRightClicked var(--animation-timing-function-toast);
}
.rt-toast-container--bottom-right.rt-toast-container--clicked {
  animation: 0.3s containerRightClicked var(--animation-timing-function-toast);
}
.rt-toast-container--bottom-left.rt-toast-container--clicked {
  animation: 0.3s containerLeftClicked var(--animation-timing-function-toast);
}
.rt-toast-container--top-left.rt-toast-container--clicked {
  animation: 0.3s containerLeftClicked var(--animation-timing-function-toast);
}
.rt-toast-container__message {
  margin: 15px 50px 20px 20px;
  font-family: var(--ff-primary);
  font-size: 1.1rem;
  color: var(--clr-message);
  overflow-wrap: break-word;
}
.rt-toast-container__progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(
    to right,
    var(--clr-progress-bar-left),
    var(--clr-progress-bar-right)
  );
  border-bottom-left-radius: var(--border-radius-toast);
  animation-duration: var(--animation-duration-toast);
  animation-timing-function: linear;
}
.rt-toast-container--animated .rt-toast-container__progress-bar {
  animation-name: progress;
}
.rt-toast-container:hover .rt-toast-container__progress-bar {
  animation-play-state: paused;
}
@keyframes containerRight {
  0% {
    right: calc(-1 * var(--width-toast));
  }
  10% {
    right: var(--offset-toast-x);
  }
  90% {
    right: var(--offset-toast-x);
  }
  100% {
    right: calc(-1 * var(--width-toast));
  }
}
@keyframes containerRightClicked {
  0% {
    right: var(--offset-toast-x);
  }
  100% {
    right: calc(-1 * var(--width-toast));
  }
}
@keyframes containerLeft {
  0% {
    left: calc(-1 * var(--width-toast));
  }
  10% {
    left: var(--offset-toast-x);
  }
  90% {
    left: var(--offset-toast-x);
  }
  100% {
    left: calc(-1 * var(--width-toast));
  }
}
@keyframes containerLeftClicked {
  0% {
    left: var(--offset-toast-x);
  }
  100% {
    left: calc(-1 * var(--width-toast));
  }
}
@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
@media (max-width: 400px) {
  .rt-toast-container {
    --offset-toast-x: calc((100vw - var(--width-toast)) / 2);
  }
}

// =========================================================
