@use "breakpoints";

// * Breakpoints *

@mixin desktop-up {
  @media (min-width: breakpoints.$desktop) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: #{breakpoints.$desktop - 0.1}) {
    @content;
  }
}

@mixin medium-desktop-up {
  @media (min-width: breakpoints.$medium-desktop) {
    @content;
  }
}

@mixin large-desktop-up {
  @media (min-width: breakpoints.$large-desktop) {
    @content;
  }
}

// * Utilities *

// * Mixin that creates an auto-filling grid given:
// * - a maximum number of columns
// * - a minimum width for each column
// * - a gap

@mixin auto-filling-grid($column-count, $column-min-width, $gap) {
  /**
  * User input values.
  */
  --grid-layout-gap: #{$gap};
  --grid-column-count: #{$column-count};
  --grid-item--min-width: min(
    100%,
    #{$column-min-width}
  ); // always set min(100%, X), so that it prevents overflows on small sizes

  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;

  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );

  gap: var(--grid-layout-gap);
}

// Mixin that inserts a ::before on the element, and takes a width, a height and a url for the image that will
// be treated as a background-image of that ::before
@mixin pseudo(
  $width,
  $height,
  $iconUrl,
  $pseudoElType: "::before",
  $display: "inline-block"
) {
  &#{$pseudoElType} {
    content: "";

    display: #{$display};

    flex-shrink: 0;

    width: $width;
    height: $height;

    background-image: url($iconUrl);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// Mixin that makes the element transition to a new opacity value whenever hover occurs
@mixin opacity-fade-on-hover(
  $transition: 250ms ease-out opacity,
  $opacity: 0.75
) {
  transition: $transition;

  &:hover {
    opacity: $opacity;
  }
}

// Mixin that styles a scrollbar for the element the mixin is applied and all of its children
@mixin scrollbar(
  $clr-scrollbar-thumb,
  $clr-scrollbar-track,
  $scrollbar-width: 4px
) {
  // * webkit
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: $clr-scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $clr-scrollbar-thumb;
  }

  &::-webkit-scrollbar:horizontal {
    height: $scrollbar-width;
  }

  // * firefox
  & {
    scrollbar-color: $clr-scrollbar-thumb $clr-scrollbar-track;
    scrollbar-width: thin;
  }
}
