@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-modal {
  --box-shadow-modal: 0 0 0 1000vmax rgba(0, 0, 0, 0.5);
  --min-width-modal: 250px;
  --max-width-modal: 650px;
  --bg-color-modal: #{colors.$clr-white};
  --gap-modal: 25px;

  display: none;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-width: var(--min-width-modal);
  width: 80%;
  max-width: var(--max-width-modal);
  padding: 2.5em;
  background-color: var(--bg-color-modal);

  text-align: center;

  box-shadow: var(--box-shadow-modal);

  z-index: 1000;

  &--visible {
    display: block;
  }

  > *:not(:last-child) {
    margin-bottom: var(--gap-modal);
  }

  &__title {
    font-size: 1.4rem;
    font-weight: typography.$fw-700;
  }

  &__cta {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100px;
    justify-content: center;
    gap: 1em;
  }
}
