.rt-sidebar {
  --width-sidebar: 450px;
  --max-width-sidebar: 90%;
  --bg-clr-sidebar: rgba(0, 0, 0, 0.9);
  --padding-sidebar: 3em;
}

.rt-overlay-sidebar--visible.rt-overlay-sidebar--visible::before {
  opacity: 0;
}

.rt-sidebar.rt-sidebar {
  width: var(--width-sidebar);
  max-width: var(--max-width-sidebar);
  background-color: var(--bg-clr-sidebar);
  padding: var(--padding-sidebar);
}
