@use "../abstracts/mixins";

.rt-phone,
.rt-whatsapp {
  display: flex;
  align-items: center;
  gap: 0.75em;

  @include mixins.pseudo(12px, 12px, "", "::before");
}

.rt-phone::before {
  background-image: url("/img/icon/phone.svg");
}

.rt-whatsapp::before {
  background-image: url("/img/icon/whatsapp.svg");
}
