@font-face {
  font-family: Manrope;
  src: url("/font/manrope/Manrope-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Manrope;
  src: url("/font/manrope/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: Manrope;
  src: url("/font/manrope/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
}
