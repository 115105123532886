@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "sass:meta";

.rt-map-list-container {
  // map list container
  --min-height-map-list-container: 600px;

  // stores area
  --min-width-stores-area: 570px;
  --relative-width-stores-area: 40%;
  --gap-stores-area: 0;

  // map
  --min-height-map: 470px;
}

.rt-map-list-container {
  display: grid;
  grid-template-areas:
    "map"
    "list";
  gap: var(--gap-stores-area);

  position: relative;

  overflow: hidden;

  @include mixins.desktop-up {
    grid-template-areas: "map list";
    grid-template-columns: 1fr min(
        var(--min-width-stores-area),
        var(--relative-width-stores-area)
      );

    height: max(100vh, var(--min-height-map-list-container));
  }
}

.rt-map-list-container--map-right {
  @include mixins.desktop-up {
    grid-template-areas: "list map";
    grid-template-columns:
      min(var(--min-width-stores-area), var(--relative-width-stores-area))
      1fr;
  }
}

.rt-stores-area {
  grid-area: list;
}

.rt-map {
  grid-area: map;
  background-color: lightgray;

  min-height: var(--min-height-map);
}
