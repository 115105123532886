@use "../abstracts/mixins";

.rt-homepage {
  --padding-inline-homepage: 1em;

  @include mixins.desktop-up {
    --padding-inline-homepage: 2em;
    // margin-right: 1em;
  }
}

.rt-header,
.rt-footer {
  padding-left: var(--padding-inline-homepage);
  padding-right: var(--padding-inline-homepage);
}

.rt-stores-area {
  padding-left: calc(var(--padding-inline-homepage) / 1);
  padding-right: calc(var(--padding-inline-homepage) / 1);
}

.rt-map {
  @include mixins.desktop-down {
    margin-left: var(--padding-inline-homepage);
    margin-right: var(--padding-inline-homepage);
  }
}
