@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-sidebar-wrapper {
  --clr-text-sidebar-wrapper: #{colors.$clr-white};

  // header
  --fs-sidebar-wrapper-header: #{typography.$fs-500};

  // body
  --fs-sidebar-wrapper-body: #{typography.$fs-700};
  --fw-sidebar-wrapper-body: #{typography.$fw-700};
}

.rt-sidebar-wrapper {
  max-height: 100%;
  overflow-y: auto;
  @include mixins.scrollbar(transparent, transparent, 0);

  color: var(--clr-text-sidebar-wrapper);
  text-align: end;
}

.rt-sidebar__header {
  margin-bottom: 3em;

  font-size: var(--fs-sidebar-wrapper-header);
  text-transform: uppercase;
}

.rt-sidebar__body {
  font-size: var(--fs-sidebar-wrapper-body);
}

.rt-sidebar__language {
  &--current {
    font-weight: var(--fw-sidebar-wrapper-body);
  }

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
}
