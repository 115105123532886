@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

// * Sara request
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rt-homepage,
.rt-concierge,
.rt-sitemap {
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  min-height: 100vh;

  font-family: typography.$ff-primary;
  font-size: typography.$fs-400;
  color: colors.$clr-gray-700;

  a {
    cursor: pointer;

    color: inherit;
  }

  button {
    border: none;
    background: none;

    text-align: center;
    color: inherit;

    cursor: pointer;
  }

  //! The .rt-preload class only serves the purpose of avoiding any transition effects
  //! that can occur when the page first loads.
  //! As soon as the DOM is loaded the class will be removed from js, allowing for transitions.
  &.rt-preload *,
  &.rt-preload *::before,
  &.rt-preload *::after {
    transition: none !important;
  }

  @media (hover: hover) {
    * {
      @include mixins.scrollbar(colors.$clr-black, white);
    }
  }
}
