@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-iw {
  // iw
  --min-width-iw: 200px;
  --max-width-iw: 250px;
  --max-height-iw: 330px;
  --padding-iw: 25px;
  --ff-iw: #{typography.$ff-primary};

  // name
  --fs-iw-name: #{typography.$fs-450};
  --fw-iw-name: #{typography.$fw-700};

  // info
  --gap-iw-info: 5px;

  --fs-iw-info: inherit;
  --fw-iw-info: inherit;

  // cta
  --gap-iw-cta: 10px;

  --fs-iw-cta: inherit;
  --fw-iw-cta: inherit;
}

// * google resets
.gm-control-active img {
  filter: brightness(0) saturate(100%);
}

.gm-style-iw {
  max-width: revert !important;
  max-height: revert !important;
  padding: 0 !important;
}

.gm-style-iw-d {
  max-height: revert !important;
  overflow: auto !important;
}

// * info window
.rt-iw {
  min-width: var(--min-width-iw);
  max-width: var(--max-width-iw);
  max-height: var(--max-height-iw);
  background-color: white;
  padding: var(--padding-iw);
  overflow-y: auto;

  font-family: var(--ff-iw);

  &__name {
    margin-bottom: 1em;

    font-size: var(--fs-iw-name);
    font-weight: var(--fw-iw-name);
  }

  // info
  &__info,
  &__cta {
    display: flex;
    flex-direction: column;
  }

  &__info {
    margin-bottom: 20px;

    font-size: var(--fs-iw-info);
    font-weight: var(--fw-iw-info);

    > *:not(:last-child) {
      margin-bottom: var(--gap-iw-info);
    }
  }

  // cta
  &__cta {
    font-size: var(--fs-iw-info);
    font-weight: var(--fw-iw-info);

    > *:not(:last-child) {
      margin-bottom: var(--gap-iw-cta);
    }
  }
}
