@use "../abstracts/typography";

.rt-overlay-sidebar.rt-overlay-sidebar {
  &::before {
    z-index: 9999;

    transition: opacity 500ms ease-out, visibility 500ms ease-out !important;
  }

  &--visible::before {
    opacity: 0.5;
  }
}

.rt-sidebar.rt-sidebar {
  z-index: 10000;
  right: 0;

  border: none;

  transform: translateX(100%);
  box-shadow: none;

  transition: transform 500ms ease-out;

  &--visible {
    transform: translateX(0);
  }
}

// ===========================================================================

// TODO: delete this

.rt-overlay-sidebar::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
  visibility: hidden;
}
.rt-overlay-sidebar--visible::before {
  opacity: 0.7;
  visibility: visible;
}
.rt-sidebar {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 320px;
  max-width: 80%;
  height: 100vh;
  padding: 2em;
  background-color: #fff;
  box-shadow: 0 0 10px #545454;
}
.rt-sidebar--left {
  border-right: 2px solid #000;
  left: -340px;
  transition: left 0.5s ease-out;
}
.rt-sidebar--left.rt-sidebar--visible {
  left: 0;
}
.rt-sidebar--right {
  border-left: 2px solid #000;
  right: -340px;
  transition: right 0.5s ease-out;
}
.rt-sidebar--right.rt-sidebar--visible {
  right: 0;
}
@keyframes slide-in {
  to {
    transform: translateX(0);
  }
}

//

// ===========================================================================
