@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-footer {
  --footer-padding-block: 2em;

  padding-top: var(--footer-padding-block);
  padding-bottom: var(--footer-padding-block);
  margin-top: auto;

  font-size: typography.$fs-350;

  &,
  .rt-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    @include mixins.desktop-down {
      justify-content: revert;
      flex-direction: column;
    }
  }

  .rt-logo-retail,
  .rt-logo-tune {
    font-family: Arial, Helvetica, sans-serif;
  }

  .rt-logo-retail {
    font-weight: 700;
  }

  .rt-logo-tune {
    color: #f0ad4e;
  }
}

.rt-footer__link:only-child {
  @include mixins.desktop-up {
    margin-left: auto;
  }
}