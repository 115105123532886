@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-select {
  --padding-select: 0.5em 1.5em;
  --max-height-select: 200px;
  --border-select: 1px solid #{colors.$clr-white};
  --bg-clr-select: #{colors.$clr-black};
  --bg-clr-select-prediction-hover: #1c1c1c;
  --width-icons-select: 10px;
  --height-icons-select: 6px;

  --ff-select: inherit;
  --fs-select: #{typography.$fs-500};
  --fw-select: inherit;
  --line-height-select: inherit;
  --clr-text-select: #{colors.$clr-white};

  --border-radius-select: 0;
  --opacity-placeholder: 1;
  --clr-thumb-scrollbar-select: #{colors.$clr-white};
  --clr-track-scrollbar-select: transparent;
}

.rt-select.rt-select.rt-select.rt-select {
  // * border radii
  .rt-select__selected-text {
    border-radius: var(--border-radius-select);
  }

  .rt-select__option-list {
    border-bottom-left-radius: var(--border-radius-select);
    border-bottom-right-radius: var(--border-radius-select);
  }

  &--opened {
    .rt-select__selected-text {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // * borders
  .rt-select__selected-text,
  .rt-select__option-list {
    border: var(--border-select);
  }

  &--opened {
    .rt-select__selected-text {
      border-bottom: none;
    }

    .rt-select__option-list {
      border-top: none;
    }
  }

  // * background
  .rt-select__selected-text,
  .rt-select__option-list {
    background-color: var(--bg-clr-select);
  }

  // * padding
  .rt-select__selected-text,
  .rt-select__option-list__option {
    padding: var(--padding-select);
  }

  // * typography
  & {
    font-family: var(--ff-select);
    font-size: var(--fs-select);
    font-weight: var(--fw-select);
    color: var(--clr-text-select);
    line-height: var(--line-height-select);
  }

  // * scrollbar
  .rt-select__option-list {
    max-height: var(--max-height-select);
    @include mixins.scrollbar(
      var(--clr-thumb-scrollbar-select),
      var(--clr-track-scrollbar-select),
      4px
    );
  }

  // * active states
  .rt-select__option-list__option:hover {
    background-color: var(--bg-clr-select-prediction-hover);
  }

  // * icons
  .rt-select__selected-text {
    @include mixins.pseudo(
      var(--width-icons-select),
      var(--height-icons-select),
      "/img/icon/chevron.svg",
      "::after"
    );
  }

  // * misc
}
