@use "../abstracts/mixins";

.rt-back-to-top.rt-back-to-top {
  position: sticky;
  float: right;
  z-index: 100000;
  right: 0;
  bottom: 0;
}

// TODO: delete this
// ====================================================
.rt-back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-radius: 100vmax;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms ease-out;
  cursor: pointer;
}
.rt-back-to-top--visible {
  visibility: visible;
  opacity: 1;
}
// ====================================================
