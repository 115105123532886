.rt-select.rt-select.rt-select.rt-select {
  &,
  .rt-select__selected-text {
    height: 100%;
  }

  .rt-select__selected-text {
    align-items: center;
  }

  .rt-select__selected-text {
    &::after {
      align-self: center;
    }
  }

  .rt-select__option-list {
    z-index: 1000;
  }
}

.rt-select--opened.rt-select--down:not(.rt-select--mini)
  .rt-select__option-list {
  margin-top: 0 !important;
}

// ===============================================
// TODO: delete this
.rt-select {
  position: relative;
  width: 100%;
  font-size: 1rem;
}
.rt-select *,
.rt-select *::before,
.rt-select *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.rt-select ul,
.rt-select ol {
  list-style: none;
}
.rt-select > * {
  background-color: #fff;
}
.rt-select__selected-text {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
}
.rt-select__selected-text::after {
  content: "";
  color: #b7b7b7;
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  transition: transform 0.3s ease;
}
.rt-select__option-list {
  display: none;
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  background-color: #fff;
  border: 2px solid #e5e5e5;
  overflow: hidden auto;
  border-radius: 4px;
}
.rt-select__option-list__option {
  padding: 10px;
  cursor: pointer;
}
.rt-select__option-list__option:hover {
  background-color: #f5f6fa;
}
.rt-select__option-list__option--selected {
  background-color: #f5f6fa;
}
.rt-select__selected-text > *,
.rt-select__option-list__option > * {
  display: flex;
  align-items: center;
  gap: 5px;
}
.rt-select__selected-text img,
.rt-select__option-list__option img {
  min-width: 15px;
}
.rt-select--opened .rt-select__selected-text::after {
  transform: rotate(-180deg);
}
.rt-select--opened .rt-select__option-list {
  display: block;
}
.rt-select--opened.rt-select--down:not(.rt-select--mini)
  .rt-select__selected-text {
  border-bottom-color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rt-select--opened.rt-select--down:not(.rt-select--mini)
  .rt-select__option-list {
  border-top: none;
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rt-select--opened.rt-select--up:not(.rt-select--mini)
  .rt-select__selected-text {
  border-top-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rt-select--opened.rt-select--up:not(.rt-select--mini) .rt-select__option-list {
  bottom: calc(20px + 1rem);
  border-bottom: none;
  margin-bottom: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rt-select--mini {
  width: 3rem;
}
.rt-select--mini .rt-select__selected-text {
  justify-content: center;
  align-items: center;
}
.rt-select--mini .rt-select__selected-text > * {
  color: #f09a04;
  text-transform: uppercase;
}
.rt-select--mini .rt-select__selected-text::after {
  display: none;
}
.rt-select--mini .rt-select__option-list__option--selected {
  display: none;
}
.rt-select--mini.rt-select--opened .rt-select__option-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  top: 0;
  left: 3.2em;
  width: max-content;
  max-width: 260px;
  max-height: 125px;
  overflow: hidden auto;
}
.rt-select--mini.rt-select--opened .rt-select__option-list li > * {
  display: flex;
  justify-content: center;
  width: 30px;
  text-transform: uppercase;
}
.rt-select--mini.rt-select--left .rt-select__option-list {
  left: revert;
  right: 3.2em;
}

// ===============================================
