.rt-autocomplete.rt-autocomplete.rt-autocomplete.rt-autocomplete {
  border: revert;

  &,
  &.rt-autocomplete--open {
    border-radius: revert;
  }

  .rt-predictions {
    overflow-y: auto;
    z-index: 1000;
  }

  .rt-prediction {
    background-color: revert;
  }
}

.rt-autocomplete-wrapper,
.rt-autocomplete,
.rt-input-container {
  height: 100%;
}

// ========================================================
// TODO: delete this
.rt-autocomplete-wrapper {
  position: relative;
  box-sizing: border-box;
}
.rt-autocomplete-wrapper ul,
.rt-autocomplete-wrapper ol {
  list-style: none;
}
.rt-autocomplete-wrapper *,
.rt-autocomplete-wrapper *::before,
.rt-autocomplete-wrapper *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.rt-autocomplete-wrapper .rt-autocomplete {
  width: 100%;
  border: 2px solid #e6e6e6;
  border-radius: 4px;
}
.rt-autocomplete-wrapper .rt-autocomplete li,
.rt-autocomplete-wrapper .rt-autocomplete input {
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #1a1a1a;
}
.rt-autocomplete-wrapper .rt-autocomplete ::placeholder {
  color: #1a1a1a;
  opacity: 0.5;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em 1em;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container input {
  width: 100%;
  border: none;
  background-color: #fff;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container input:focus {
  outline: none;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container .rt-icon {
  display: grid;
  place-items: center;
  background-color: #fff;
  border: none;
  cursor: pointer;
}
.rt-autocomplete-wrapper
  .rt-autocomplete
  .rt-input-container
  .rt-icon[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container .rt-icon--left {
  order: -1;
}
.rt-autocomplete-wrapper
  .rt-autocomplete
  .rt-input-container
  .rt-icon.rt-clear-icon {
  display: none;
}
.rt-autocomplete-wrapper
  .rt-autocomplete
  .rt-input-container
  .rt-icon.rt-clear-icon.rt-icon--visible {
  display: grid;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-input-container .rt-icon img {
  width: 12px;
  height: 12px;
  object-fit: contain;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-predictions {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  border: 2px solid #e6e6e6;
  border-top: 0;
}
.rt-autocomplete-wrapper .rt-autocomplete .rt-predictions .rt-prediction {
  background-color: #fff;
  padding: 0.5em 1em;
  cursor: pointer;
}
.rt-autocomplete-wrapper
  .rt-autocomplete
  .rt-predictions
  .rt-prediction--active {
  background-color: #f5f6fa;
}
.rt-autocomplete-wrapper
  .rt-autocomplete
  .rt-predictions
  .rt-prediction--zero-results {
  font-size: 0.7rem;
}
.rt-autocomplete-wrapper .rt-autocomplete.rt-autocomplete--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rt-autocomplete-wrapper
  .rt-autocomplete.rt-autocomplete--open
  .rt-predictions {
  display: block;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
