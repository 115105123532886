@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-directions-pane {
  // * directions pane
  --padding-directions-pane: 3em;
  --bg-clr-directions-pane: #{colors.$clr-white};

  // * close icon
  --offset-top-close-icon-directions-pane: 20px;
  --offset-right-close-icon-directions-pane: 20px;

  // * travel modes
  --gap-travel-modes-directions-pane: 1em;
  --size-travel-mode-icon-directions-pane: 40px;

  // * locations
  --width-icon-locations-directions-pane: 22px;
  --height-icon-locations-directions-pane: 27px;
  --padding-locations-directions-pane: 0.5em 1em;
  --bg-clr-locations-directions-pane: #{colors.$clr-white};
  --gap-locations-directions-pane: 1.5em;

  // * instructions
  --gap-instructions-area-directions-pane: 30px;

  --width-icon-origin-label-directions-pane: 34px;
  --height-icon-destination-label-directions-pane: 42px;
  --fs-labels-directions-pane: 16px;
  --fw-labels-directions-pane: #{typography.$fw-700};

  --margin-bottom-instruction-directions-pane: 0.8em;
  --gap-instruction-directions-pane: 0.25em;
  --fs-instruction-directions-pane: #{typography.$fs-350};
  --line-height-instruction-directions-pane: 1.4;
  --min-width-instruction-number-directions-pane: 3ch;
}

// ! For the currently selected travel mode, I prefer
// ! a mixin over a CSS custom prop
// ! that because I want its styling to be as flexible as possible,
// ! since in each storelocator the styling for it can be different

@mixin travel-mode-selected {
  .rt-directions-pane__travel-mode {
    padding: 0.5em 1.5em;
  }

  .rt-directions-pane__travel-mode--selected {
    background-color: colors.$clr-gray-400;

    border-radius: 15px;

    img {
      filter: brightness(0) saturate(100%);
    }
  }
}

.rt-directions-pane {
  display: none;
  flex-direction: column;

  grid-area: list;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;

  padding: var(--padding-directions-pane);
  background-color: var(--bg-clr-directions-pane);

  transform: translateX(100%);
  visibility: hidden;
  overflow-y: auto;

  transition: transform 500ms ease-out, visibility 500ms ease-out;

  @include mixins.desktop-up {
    display: flex;
  }
}

.rt-directions-pane--visible.rt-directions-pane--visible.rt-directions-pane--visible {
  transform: translateX(0);
  visibility: visible;
}

.rt-map-list-container--map-right .rt-directions-pane {
  transform: translateX(-100%);
}

// * close
.rt-directions-pane__close-btn {
  position: absolute;
  top: var(--offset-top-close-icon-directions-pane);
  right: var(--offset-right-close-icon-directions-pane);
}

// * travel modes
.rt-directions-pane__travel-modes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-travel-modes-directions-pane);

  margin-bottom: 4em;

  @include travel-mode-selected;
}

.rt-directions-pane__travel-mode {
  img {
    width: var(--size-travel-mode-icon-directions-pane);
    height: var(--size-travel-mode-icon-directions-pane);
  }
}

// * locations
.rt-directions-pane__locations {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 4em;
}

.rt-locations-origin {
  .rt-input-container {
    @include mixins.pseudo(
      var(--width-icon-locations-directions-pane),
      var(--height-icon-locations-directions-pane),
      "/img/pin/pin-user.svg"
    );
  }

  // ! We don't want the search icon in this autocomplete
  // ! The icon should never appear in the HTML in the first place, this is just for precaution
  .rt-icon {
    display: none !important;
  }

  .rt-autocomplete {
    --padding-autocomplete: var(--padding-locations-directions-pane);
    --bg-clr-autocomplete: var(--bg-clr-locations-directions-pane);
    // --bg-clr-autocomplete-prediction-hover: white;
    --gap-autocomplete: var(--gap-locations-directions-pane);
    --fs-autocomplete: #{typography.$fs-400};
    --clr-text-autocomplete: #{colors.$clr-black};
    --opacity-placeholder-autocomplete: 0.5;
    // --clr-thumb-scrollbar-autocomplete: #{colors.$clr-black};
  }
}

.rt-locations-destination {
  display: flex;
  align-items: center;
  gap: var(--gap-locations-directions-pane);

  background-color: var(--bg-clr-locations-directions-pane);
  padding: var(--padding-locations-directions-pane);
  border: 1px solid colors.$clr-black;

  border-radius: 100vmax;

  @include mixins.pseudo(
    var(--width-icon-locations-directions-pane),
    var(--height-icon-locations-directions-pane),
    "/img/pin/pin-store.svg"
  );

  &::before {
    flex-shrink: 0;
  }
}

// * instructions area
.rt-directions-pane__instructions-area {
  > *:not(:last-child) {
    margin-bottom: var(--gap-instructions-area-directions-pane);
  }
}

.rt-origin-label,
.rt-destination-label {
  display: flex;
  align-items: center;
  gap: 14px;

  font-size: var(--fs-labels-directions-pane);
  font-weight: var(--fw-labels-directions-pane);
}

.rt-origin-label {
  @include mixins.pseudo(32px, 38px, "/img/pin/pin-user.svg");
}

.rt-destination-label {
  @include mixins.pseudo(32px, 38px, "/img/pin/pin-store.svg");
}

.rt-instruction {
  display: flex;
  gap: var(--gap-instruction-directions-pane);

  font-size: var(--fs-instruction-directions-pane);
  line-height: var(--line-height-instruction-directions-pane);

  &:not(:last-child) {
    margin-bottom: var(--margin-bottom-instruction-directions-pane);
  }

  > strong:first-child {
    min-width: var(--min-width-instruction-number-directions-pane);
    overflow-wrap: break-word;
  }
}
