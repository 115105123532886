@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-next-opening {
  display: inline-block;

  // useful in store card and other places
  // where it becomes a flex item, and it always
  // need to stay align to the left
  align-self: flex-start;

  background-color: colors.$clr-black;
  padding: 0.35em 0.5em;
  margin-bottom: 1em;

  font-size: typography.$fs-100;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
